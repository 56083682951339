/* Fonts */

@font-face {
  font-family: "Jura";
  src: local("Jura"), url(./fonts/Jura-VariableFont_wght.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url(./fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"),
    url(./fonts/RobotoMono-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Muli";
  src: local("Muli"), url(./fonts/Muli-VariableFont_wght.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), url(./fonts/Nunito-Regular.ttf) format("truetype");
}

/* Common CSS */

body {
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3,
h6 {
  margin: 0;
}

.fa-icon {
  color: var(--color-icon);
}

a {
  color: #349aeb;
}

a:hover {
  color: #3f86c0;
  text-decoration: none;
}

.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 0.4;
}

.mono {
  font-family: RobotoMono;
  line-height: 25px;
  font-size: 14px;
}

.sm {
  font-size: 14px;
}

.subtext {
  color: var(--color-subtext);
}

.text-small {
  font-size: 80%;
}

.display-block {
  border: solid 1px var(--color-border);
  padding: 1rem;
  border-radius: 10px;
  background-color: var(--color-highlight);
  color: var(--color-text);
}

.code-block {
  border: solid 1px var(--color-border);
  border-radius: 10px;
  background-color: var(--color-highlight);
  color: var(--color-text);
}

hr {
  border-color: var(--color-border);
}

/* Themed Center Spinner */

.center-spinner {
  position: absolute;
  top: 40%;
  left: 50%;
}

.center-spinner .spinner-border {
  color: var(--color-spinner);
}

/* Themed Card */

.card {
  background-color: var(--color-card-bg);
  color: var(--color-text);
}

.card-header {
  background-color: var(--color-card-header);
}

/* Themed Form */

.form-control::placeholder {
  color: var(--color-subtext);
}

.form-control {
  background-clip: border-box !important;
  border: 1px solid var(--color-border) !important;
  color: var(--color-text) !important;
  background-color: var(--color-searchbar) !important;
}

.form-control:focus {
  z-index: 0 !important;
  outline: 0;
  box-shadow: none;
}

/* Themed Dropdown */

.dropdown-toggle {
  outline: 0;
  box-shadow: none !important;
  border: 1px solid var(--color-border) !important;
  color: var(--color-subtext) !important;
  background-color: var(--color-searchbar) !important;
}

.dropdown-toggle:hover {
  color: var(--color-text) !important;
}

.dropdown-menu {
  color: var(--color-subtext);
  background-color: var(--color-searchbar);
  border: 1px solid var(--color-border);
}

.dropdown-divider {
  border-color: var(--color-border);
}

.dropdown-item {
  color: var(--color-text) !important;
}

.dropdown-item:hover {
  background-color: var(--color-highlight);
}

/** Themed Pagination */

.pagination .page-item .page-link {
  background-color: var(--color-table-bg);
  border-color: var(--color-border);
}

.pagination .page-item:focus {
  outline: none;
}

.pagination .page-item.active .page-link {
  background-color: var(--color-zil-green);
  border-color: var(--color-zil-green);
}

.pagination .page-link {
  z-index: 0;
  color: var(--color-zil-green);
}

.pagination .page-link:hover {
  background-color: var(--color-highlight);
}

.pagination .page-link:focus {
  box-shadow: none;
}

/* Themed Button */

.btn {
  color: var(--color-subtext);
  background-color: var(--color-btn);
  border: 1px solid var(--color-border);
}

.btn:hover {
  color: var(--color-text);
  border: 1px solid var(--color-border);
  background-color: var(--color-btn-hover);
}

.btn:focus,
.btn:active,
.btn:focus:active {
  color: var(--color-subtext) !important;
  background-color: var(--color-btn) !important;
  border: 1px solid var(--color-border) !important;
  outline: 0;
  box-shadow: none !important;
}
