.custom-footer {
  height: 170px;
  background-color: #092532;
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
}

.custom-footer a {
  padding: 0 0.3rem;
  color: rgb(205, 205, 205);
}

.social-header {
  padding-left: 5px;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.4);
}

.related-links-header {
  padding-left: 0.2rem;
  color: rgba(255, 255, 255, 0.4);
}

.theme-switch :hover {
  background-color: hsla(0, 0%, 100%, 0.2);
}

.theme-switch {
  border-radius: 3px;
  padding: 0;
  background-color: rgba(248, 249, 250, 0.1);
  width: 26px;
  height: 26px;
  outline: 0 !important;
  border: solid 1px rgba(255, 255, 255, 0.7);
}

.theme-icon-div {
  margin-top: -0.35px;
}

.theme-icon {
  font-size: 13px;
  background: none !important;
}
