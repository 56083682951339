/* Header */

.txblock-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Card */

.txblock-details-card {
  width: 100%;
  margin: 1rem 0;
  white-space: nowrap;
}

.txblock-detail {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid var(--color-border);
}
