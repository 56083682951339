.txblock-card {
  width: 100%;
  margin-top: 1rem;
}

.txblock-card-header {
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
