.dashboard-container .card {
  border-radius: 10px;
}

.dashboard-container .card-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dashboard-container .card-body {
  border-radius: 10px;
  box-shadow: 1px 2px 6px 7px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(166, 173, 201, 0);
  padding: 1.25rem 1rem;
}
