/* Tabs */

.tabs-card {
  min-height: 300px;
  margin: 1rem 0;
}

.tabs-card-header .nav {
  margin: -0.25rem -0.625rem -0.875rem;
}

.tabs-card-header a {
  outline: none;
}

.tabs-card-header a:hover {
  color: rgb(0, 198, 195);
  border-bottom-color: transparent !important;
}

.tabs-card-header .nav-tabs .nav-link.active {
  color: rgb(0, 198, 195);
  border-bottom-color: rgb(0, 198, 195) !important;
}

.tabs-card-header .nav-item {
  color: var(--color-text);
  background: none !important;
}

.tabs-card-header .nav-tabs,
.tabs-card-header .nav-link {
  border: 0;
  border-bottom: 2px solid transparent;
}

/** Receipt Tab **/

.receipt-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 4px;
  width: 100%;
}

.receipt-table tr td:first-child,
.receipt-table tr th:first-child {
  width: 200px;
}

.receipt-table th {
  font-family: Muli;
  font-weight: 500;
  min-width: 200px;
  vertical-align: top;
}
