/* Header */

.address-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Card */

.address-details-card {
  width: 100%;
  margin: 1rem 0;
  white-space: nowrap;
}

.address-details-card .card {
  border-radius: 0;
}

.address-detail {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid var(--color-border);
}

/* Account Contracts  */

.num-contracts {
  margin: 1rem 1.25rem;
  padding: 0.5rem;
  height: fit-content;
  border: solid 1px var(--color-border);
  display: inline-flex;
  align-items: center;
}

.contract-pagination {
  margin: 1rem 1.25rem;
}

/* Account Contracts Card */

.acc-contract-card {
  border-color: var(--color-border);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.acc-contract-card .card-body:hover {
  background-color: var(--color-highlight);
}

.acc-contract-card .card-body {
  cursor: pointer;
  padding: 1rem;
}

.acc-contract-card .card-body div {
  display: flex;
  justify-content: space-between;
}

.acc-contract-card .collapse pre,
.acc-contract-card .collapsing pre {
  padding: 1rem;
  border-top: solid 1px var(--color-border);
  margin-bottom: 0;
}

.acc-contract-card .collapse,
.acc-contract-card .collapsing {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  white-space: pre-wrap;
}

/* Contract Details Page */

.owner-span {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
