/* Header */

.dsblock-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Card */

.dsblock-details-card {
  width: 100%;
  margin-top: 1rem;
}

.dsblock-detail {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid var(--color-border);
}

.dsblock-signature {
  line-height: 25px;
  font-size: 12.5px;
  max-width: calc(100% - 90px);
}

/* Miner Info */

.miner-card {
  width: 100%;
  margin-top: 1rem;
  user-select: none;
}

.miner-card .row {
  margin: 0;
}

.shard-toggle {
  margin-top: -0.5rem;
}

.showmore-container {
  text-align: center;
  background-color: var(--color-card-bg);
  user-select: none;
  border: solid 1px rgba(0, 0, 0, 0.125);
  border-radius: 25px;
  margin-top: 1rem;
  cursor: pointer;
}

.showmore-container .row {
  padding: 0.25rem 0;
  justify-content: center;
}
