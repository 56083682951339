/* Card */

.isinfo-card {
  width: 100%;
  margin: 1rem 0;
}

.isinfo-detail {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid var(--color-border);
}
