.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: var(--color-border);
  border-style: dashed;
  background-color: var(--color-dropzone-bg);
  color: var(--color-subtext);
  outline: none;
}

.dropzone-prompt {
  user-select: none;
}
